import './single-image.scss';

import { OverlaySlider } from '../../utilities/js/overlay-slider';

class SingleImage {
    constructor (element) {
        this.$SingleImage = element;
    }

    initialize () {
        // init overlay slider
        // eslint-disable-next-line no-new
        new OverlaySlider(this.$SingleImage);
    }
}

export { SingleImage };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$SingleImage = $context.querySelectorAll('[data-single-image="root"]');
        for (let i = 0; i < $$SingleImage.length; i++) {
            const $SingleImage = new SingleImage($$SingleImage[i]);
            $SingleImage.initialize();
        }
    }
});
